import React from 'react';

const Hollidays = () => {
    return (
        <div>
            <div className='text-light mt-4'>
                this is the hollidays page
            </div>
        </div>
    );
};

export default Hollidays;