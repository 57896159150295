import {useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBModal , MDBModalDialog , MDBModalContent} from 'mdb-react-ui-kit'


const LoadingToRedirect = () => {

    const [count , setCount] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((currentCount) => currentCount - 1);
        },1000);
        count === 0 && navigate('/login');
        return () => clearInterval(interval);
    },[count]);

    return (
        // <div className='vh-100 gradient-cutsom'>
        //     <MDBModal show={true}>
        //         <MDBModalDialog centered>
        //             <MDBModalContent>             
        //             </MDBModalContent>
        //         </MDBModalDialog>
        //     </MDBModal>
        // </div>
        <div className="loader-container gradient-cutsom">
            <div className="loader"></div>
        </div>

    );
};

export default LoadingToRedirect;