import React from 'react';
import Main from '../components/main';

const Dashboard = () => {
    
    return (
        
        <div>
            <Main/>
        </div>
        
    );
};

export default Dashboard;