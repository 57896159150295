import {createSlice , PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface MembreState {
    data :[]
    current_page:number
    first_page_url:string
    from:number
    last_page:number
    last_page_url:string
    next_page_url:string
    path:string
    per_page:number
    prev_page_url:string
    to:number
    total:number
}

const initialState: MembreState = {
    data : [],
    current_page:0,
    first_page_url:"",
    from:0,
    last_page:0,
    last_page_url:"",
    next_page_url:"",
    path:"",
    per_page:0,
    prev_page_url:"",
    to:0,
    total:0
}

export const membreSlice = createSlice({
    name: 'membres',
    initialState,
    reducers: {
        setMembres: (state, action: PayloadAction<MembreState>) => {
            state.data = action.payload['data'];
            state.current_page = action.payload['current_page'];
            state.first_page_url = action.payload['first_page_url'];
            state.from = action.payload['from'];
            state.last_page = action.payload['last_page'];
            state.last_page_url = action.payload['last_page_url'];
            state.next_page_url = action.payload['next_page_url'];
            state.path = action.payload['path'];
            state.per_page = action.payload['per_page'];
            state.prev_page_url = action.payload['prev_page_url'];
            state.to = action.payload['to'];
            state.total = action.payload['total'];
        },
    }
})

export const selectMembres = (state: RootState) => state.membres;

export const {setMembres} = membreSlice.actions;

export default membreSlice.reducer;

