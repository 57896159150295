import { ApiSlice } from '../api/apiSlice';

export const statusApi = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        statusUser: builder.mutation({
            query: credentials => ({
                url: 'dashboard',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }),
        sendStatus: builder.mutation({
            query: (body:{state_id : any}) => ({
                url: 'changeState',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            })
        }),
        logout : builder.mutation({
            query: () => ({
                url: 'logout',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        }),
    }),
})

export const {useStatusUserMutation , useSendStatusMutation , useLogoutMutation} = statusApi;


