import React from 'react';

const PageNotFound = () => {
    return (
        <div>
            <div className="not-found-container">
                <h1 className="not-found-title">Page Not Found</h1>
                <p className="not-found-message">The page you are looking for does not exist.</p>
            </div>
        </div>
    );
};

export default PageNotFound;