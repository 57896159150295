import '../../App'
import React, { useState ,useEffect } from "react";
import { getDashboardState } from '../../features/dashboardSlice';
import { useAppDispatch } from '../hooks';
import { useStatusUserMutation ,useSendStatusMutation } from '../services/dashboardApi';
import { useSelector } from 'react-redux';
import { MDBSpinner} from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';



interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  color: string;
}

interface CustomSelectProps {
  options: Option[];
}

export default function CustomSelect(props: CustomSelectProps) {
  
  
  const [selectedOption, setSelectedOption] = useState("");

  const dispatch = useAppDispatch();

  const [statusUser ,
    {
      data:statusData ,
      isLoading:isStatusLoading ,
      isSuccess:isStatusSuccess ,
      isError:isStatusError ,
      error:statusError
    }
  ] = useStatusUserMutation();

  const [sendStatus ,
    {
      data:sendData ,
      isLoading:isSendLoading ,
      isSuccess:isSendSuccess ,
      isError:isSendError ,
      error:sendError
    }
  ] = useSendStatusMutation();

  const handle = async () => {
    await statusUser({id: 0 , name: null});
  }

  useEffect(() => {
    handle();
  },[]);
  

  useEffect(() => {
    if(isStatusSuccess){
      dispatch(getDashboardState(statusData.data))
    }

    if(isStatusError){
      toast.error('Error !', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,  
      draggable: true,
      progress: undefined,
      });
    }

  },[isStatusSuccess, statusData, isStatusError, statusError]);


  const states = useSelector((state: any) => state.dashboard.states);


  if (isStatusLoading) return <div><MDBSpinner role='status' color='light'><span className='visually-hidden'>Loading...</span></MDBSpinner></div>;
 

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    
    setSelectedOption(event.target.value);

    await sendStatus({ state_id : event.target.value });

    if(isSendSuccess){
      toast.success('State changed successfully !', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,  
      draggable: true,
      progress: undefined,
      });
    }
  };

  return (
    <select 
        onChange={handleChange} 
        style={
          selectedOption === "1" ? { color: "#0253BC" } : 
          selectedOption === "2" ? { color: "#EB5757" } : 
          selectedOption === "3" ? { color: "#F2994A" } : 
          selectedOption === "4" ? { color: "#27AE60" } : undefined
        }
        className='form-select form-select-md'
    >
      <option style={{textAlign: 'center', padding: '5px 0px'}} >Select State</option>
      {states.map((stat:any) => (
        <option 
          key={stat.id} 
          value={stat.id} 
          style={{
            ...(stat.title === 'Working' ? { color: "#27AE60"  } : {}
            && stat.title === 'Break' ? { color: "#EB5757" } : {}
            && stat.title === 'Lunch' ? { color: "#F2994A" } : {}
            && stat.title === 'Meet' ? { color: "#0253BC" } : {}
            && stat.title === 'Offline' ? { color: "#BEBEBE" } : {}),
            textAlign: 'center' , padding: '5px 0px' , height: '20px'
          }} >
          {stat.title}
        </option>
      ))}
    </select>
  );
}

