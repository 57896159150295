import React from 'react';
import logo from '../../img/Layer-645 1.png'
import CustomSelect from './customSelect';
import img from '../../img/portrait-handsome-european-male-student-has-gentle-smile-face-happy-hear-pleasant-news-stands-delighted-wears-round-spectacles-orange-jumper.png'
import { useSelector } from 'react-redux';



const NavBar = () => {

    const {auth} = useSelector((state: any) => state)
    
    return (
        <nav id="main-navbar" className="navbar navbar-expand-lg navbar-light header-dash fixed-top" >
            <div className="container-fluid d-flex justify-content-start">
            <button
                    className="navbar-toggler"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target="#sidebarMenu"
                    aria-controls="sidebarMenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                <i className="fas fa-bars"></i>
            </button>
        
            <a className="navbar-brand" href="#">
                <img
                    src={logo}
                    height="40"
                    alt=""
                    loading="lazy"
                />
            </a>
            <div className='d-flex justify-content-start align-items-center img-select'>
                <img src={auth.user?.avatarUrl} className="rounded-circle img-user mt-2" width={40} alt="..."/>
                    <div className='w-5 ms-5'>
                        <CustomSelect options={[]} />
                    </div>
                </div>
            </div>

        </nav>
    );
};

export default NavBar;