import {createApi ,fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { setAuth ,logout } from '../../features/authSlice';
import { RootState } from '../store';


export const BaseQuery = fetchBaseQuery({
    baseUrl: 'https://mmintegrity.chimatchapp.com/api/v1/',
    
    prepareHeaders: (headers, { getState }) => {

        const token = (getState() as RootState).auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers;
    }
})

export const BaseQueryWithoutToken = async (args: any, api: any, extraOptions: any ) => {

    let result = await BaseQuery(args, api, extraOptions);


    const Msg = JSON.stringify(result?.error?.data);
    const MgsError = JSON.parse(Msg || '{}');

    if(result?.error?.status === 400 && MgsError?.error === 'token_invalid'){
        api.dispatch(logout());
    }

    if (result?.error?.status === 401 && MgsError?.error === 'token_expired' ) {
        
        // send refresh token to get new access token

        const gatAll = localStorage.getItem('USER');
        const User = JSON.parse(gatAll || '{}');

        const token = api.getState().auth.token;

        const refreshResult = await BaseQuery({
            url: 'refresh',
            method: 'POST',
            
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                token: token
            }
        }, api, extraOptions);

        

        if (refreshResult?.data) {
            
            const user = api.getState().auth.user;
            const token = refreshResult?.data;
            

            const getToken = JSON.stringify(token);

            const FormatString = JSON.parse(getToken || '{}');

            

            // store the new token
            api.dispatch(setAuth({ user: user, token: FormatString?.token }))

            // retry the initial request with the new token
            result = await BaseQuery(args, api, extraOptions);
        }     
    }
    return result;
}


export const ApiSlice = createApi({
    baseQuery: BaseQueryWithoutToken,
    endpoints: (builder) => ({
    }),
})




