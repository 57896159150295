import {  MDBInput} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBTable, MDBTableBody, MDBSpinner} from 'mdb-react-ui-kit';
import moment from 'moment';
import { useGetActivitiesQuery , useSearchDataActivitiesMutation , usePaginationAvtivitiesMutation} from '../../services/activityApi';
import { addActivity } from '../../../features/activitySlice';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';


const Membres = () => {

  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState('');

  const [sortOption, setSortOption] = useState('');

  const activity = useSelector((state: any) => state.activities);

  const initialRows = activity.data;

  const current_page = activity.current_page;

  const per_page = activity.per_page;

  const total = activity.total;

  const last_page = activity.last_page;

  const [rows, setRows] = useState<any[]>([]);

  const [searchDate , setSearchDate] = useState('')

  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = total;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rows.slice(indexOfFirstItem, indexOfLastItem);

  // const npages = Math.ceil(rows.length / itemsPerPage);
  // const pages = new Array(npages).fill(null).map((v, i) => i + 1);

  // pagination

  const [data1, setData] = useState([]); // To store the paginated data
  const [currentPage1, setCurrentPage1] = useState(1); // To track the current page
  const [lastPage, setLastPage] = useState(1);


  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetActivitiesQuery({});


  React.useEffect(() => {
    if (isSuccess) {
      dispatch(addActivity(data.data))
    }
  })


  React.useEffect(() => {
    const initializeRows = async () => {
      if (activity.data) {
        setRows(activity.data);
        setData(activity.data.data);
        setLastPage(activity.data.last_page);
      } 
    };

    initializeRows();
  }, [data, activity]);

  
  const [searchDateActivity ,
    {
      data:filterData ,
      isLoading:isfilterLoading ,
      isSuccess:isfilterSuccess ,
      isError:isfilterError ,
      error:filterError
    }
  ] = useSearchDataActivitiesMutation();

  const [
    paginationActivity ,
    {
      data:paginationData ,
      isLoading:ispaginationLoading ,
      isSuccess:ispaginationSuccess ,
      isError:ispaginationError ,
      error:paginationError
    }
  ] = usePaginationAvtivitiesMutation();

  

  const change = async (page:any) => {
    setCurrentPage(page.selected + 1);
    await paginationActivity({page : page.selected + 1})
  };

  // const next = async () => {
  //   if (currentPage < pages.length) {
  //     setCurrentPage(currentPage + 1);
  //     await paginationActivity({page : currentPage + 1})
  //   }
  // };

  // const prev = async () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //     await paginationActivity({page : currentPage - 1})
  //   }
  // };

  React.useEffect(() => {
    if(ispaginationSuccess){
      setRows(paginationData.data.data)
    }
  })

  React.useEffect(() => {
    if (filterData) {
      setRows(filterData.data.data)
      setCurrentPage(currentPage)
    }
  })

  const handleSearchChange = async (date:any) => {

    setSearchDate(date)

    const value = new Date(date).valueOf();

    try {
      if (value) {
        await searchDateActivity({date : value});
      } else {
        setRows(initialRows);
      }
    }
    catch (err:any) {
      toast.success(err.message || 'Something went wrong. Please try again later.');
    }

    // if (isNaN(value)){
    //   setRows(initialRows)
    // }else{
    //   await searchDateActivity({date : value});
    // }
    // await searchDateActivity({date : value});

  };

  const filteredRows = rows.filter((data:any) => {
    if (data.time != null && typeof data.time === 'string') {
      const dateTime = moment(searchDate).format('ddd MMM DD YYYY HH:mm:ss')
      return data.time.toLowerCase().includes(dateTime.toLowerCase());
    }
    return false;
  })

  
 

  return (
    <MDBContainer className='my-3 bg-white rounded-5 d-flex flex-column justify-content-between section-activity'>
      <div className='py-4 d-flex justify-content-between flex-wrap gap-3 px-5'>
            <h3 className=''>Activity</h3>
            <MDBInput type='datetime-local' value={searchDate}  onChange={(e) => handleSearchChange(e.target.value)} placeholder='Today'/>
      </div>
      <MDBTable responsive>
        <MDBTableBody>
        {
          isLoading || isfilterLoading || ispaginationLoading ? (
            <tr>
              <td colSpan={4} className='text-center'>
              <MDBSpinner role='status'>
                <span className='visually-hidden'>Loading...</span>
              </MDBSpinner>
              </td>
            </tr>
          ) :
          rows.length === 0 ? (
            <tr>
              <td colSpan={5} className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <p className='text-center'>No data found</p>
                  </div>
                </div>
              </td>
            </tr>
          ) :
          
          rows.map((element:any) => (
            <tr>
              <td className='align-middle text-center'>{element.time}</td>
              <td className='align-middle text-center'>{element.message}</td>
              <td className='align-middle text-center'>
              {
                element.previous_state === 'Break'
                ? <div className='status-activity' style={{border: '1px solid #EB5757' , color: '#EB5757'}} >{element.previous_state}</div>
                : element.previous_state === 'Working'
                ? <div className='status-activity' style={{border: '1px solid #27AE60' , color: '#27AE60'}} >{element.previous_state}</div>
                : element.previous_state === 'Lunch'
                ? <div className='status-activity' style={{border: '1px solid #E28A3C' , color: '#E28A3C'}} >{element.previous_state}</div>
                : element.previous_state === 'Meet'
                ? <div className='status-activity' style={{border: '1px solid #0253BC' , color: '#0253BC'}} >{element.previous_state}</div>
                : <div className='status-activity' style={{border: '1px solid #494949' , color: '#494949'}} >{element.previous_state}</div>
              }
              </td>
              <td className='align-middle text-center'>to</td>
              <td className='align-middle text-center'>
              {
                element.next_state === 'Break'
                ? <div className='status-activity' style={{border: '1px solid #EB5757' , color: '#EB5757'}} >{element.next_state}</div>
                : element.next_state === 'Working'
                ? <div className='status-activity' style={{border: '1px solid #27AE60' , color: '#27AE60'}} >{element.next_state}</div>
                : element.next_state === 'Lunch'
                ? <div className='status-activity' style={{border: '1px solid #E28A3C' , color: '#E28A3C'}} >{element.next_state}</div>
                : element.next_state === 'Meet'
                ? <div className='status-activity' style={{border: '1px solid #0253BC' , color: '#0253BC'}} >{element.next_state}</div>
                : <div className='status-activity' style={{border: '1px solid #494949' , color: '#494949'}} >{element.next_state}</div>
              }
              </td>
            </tr>
          ))

        }
        </MDBTableBody>
      </MDBTable>
      <div className='py-2 px-3 d-flex justify-content-center'>
        <nav aria-label='Page navigation example'>
          <ReactPaginate
            pageCount={last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={change}
            containerClassName={'pagination'}
            activeClassName={'active'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'nextpeviousLink'}
            previousLinkClassName={'nextpeviousLink'}
            nextClassName={'nextpeviousLink'}
            nextLinkClassName={'nextpeviousLink'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            nextLabel={'»'}
            previousLabel={'«'}
            className='pagination'
          />
        </nav>
      </div>
    </MDBContainer>
    );
};

export default Membres;
