import React, { useState , useEffect} from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { MDBContainer ,MDBSpinner} from 'mdb-react-ui-kit';
import { useGetTimeLineQuery } from '../../services/activityApi';
import { addTimeLine } from '../../../features/activitySlice';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../features/authSlice';



const TimeLine = () => {

  const dispatch = useAppDispatch();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTimeLineQuery({});

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(addTimeLine(data.data))
    }
  })

  const timeLine = useSelector((state: any) => state.activities.timeLineData);
  const initialRows: Array<[any, string, string]> = [];
  const ArrayWith: [number, string][] = []
  const WithFinal:[number, string][] = []
  
  const [value, setValue] = useState(initialRows);

  // Calcul porcentage date of day 
  const CalculPercentageDateStatus  = (timeString:string) => {

    const startLogin = timeLine[0].end;

    // console.log(timeLine)

    const [hoursStart, minutesStart] = startLogin.split(':');

    const startLoginTime = new Date();

    startLoginTime.setHours(Number(hoursStart), Number(minutesStart), 0);

    // const endLogin = new Date(timeLine[timeLine.length - 1].end);
    
    const startTime = new Date();

    startTime.setHours(8, 0, 0); 
    
    const endTime = new Date();

    endTime.setHours(20, 0, 0); 
    
    const totalDuration = (endTime.getTime() - startTime.getTime()) / (1000 * 60); 
    // const totalDuration = (endTime.getTime() - startLoginTime.getTime()) / (1000 * 60); 
    
    const currentTime = new Date();

    const [hours, minutes] = timeString.split(':');

    currentTime.setHours(Number(hours), Number(minutes), 0);
    
    const currentMinutes = (currentTime.getHours() * 60) + currentTime.getMinutes(); 
    
    const percentage = (currentMinutes - (startTime.getHours() * 60)) / totalDuration * 100; 
    // const percentage = (currentMinutes - (startLoginTime.getHours() * 60)) / totalDuration * 100; 
    
    return percentage.toFixed(2);
  }

  timeLine.map((row: any , i: any) => {
    
    initialRows.push([CalculPercentageDateStatus(row.end), String(row.state) , String(row.end)]);
    const dateTime = new Date();
    const hour = dateTime.getHours();

    // if((hour === 8) || (hour <= 20 )){

    //   console.log('push')
      
    // }else{
    //   console.log('not push')
      
    // }

  })

  // const initialRows1 = [
  // ]

  // const initialRows: [number, string , string][] = [
  // ]

  // push porcentage and status in array
  const porcentage = () => {
    initialRows.map((row , i) => {
      ArrayWith.push([Number(row[0]), String(row[1])]);
    })
  }
  porcentage()

  // CalculWithprogress
  const CalculWithprogress  = () => {
    ArrayWith.map((row , i:any) => {
      if (i === 0) {
        WithFinal.push(row);
      } else {
        WithFinal.push([row[0] - ArrayWith[i - 1][0] , (row[1])]);
      }
    })
  }
  CalculWithprogress() 

  // React.useEffect(() => {
  //   const initializeRows = async () => {
      
  //   };

  //   initializeRows();
  // });

  // const { token , user } = useSelector(selectAuth);

  // const initialRowsOffline: Array<[any, string, string]> = [];

  // if(token && user){
  //   const dateTime = new Date();
  //   const hour = dateTime.getHours();
  //   const minutes = dateTime.getMinutes();
  //   const time = hour + ':' + minutes;
    
  //   initialRowsOffline.push([CalculPercentageDateStatus(time), String('Offline') , String(time)]);
  // }

  // console.log(initialRowsOffline)


 
  return (
    <div className='mt-3 bg-white rounded-5'>
        <div className='d-flex justify-content-around py-3 flex-wrap'>
            <h3>TimeLine</h3>
            <ul className='d-flex list-status flex-wrap gap-2'>
                <li>
                    <div className='color-working'></div>
                    <div className='status-content'>Working</div>
                </li>
                <li>
                    <div className='color-Lunch'></div>
                    <div className='status-content'>Lunch</div>
                </li>
                <li>
                    <div className='color-Break'></div>
                    <div className='status-content'>Break</div>
                </li>
                <li>
                    <div className='color-InMeeting'></div>
                    <div className='status-content'>In Meeting</div>
                </li>
                <li>
                    <div className='color-Offline'></div>
                    <div className='status-content'>Offline</div>
                </li>
            </ul>
        </div>
        <div className='d-flex justify-content-center align-items-center w-100 pb-5 px-2'>
        <Box sx={{ width: 1000 }}>
          <Slider
            className='gourp-slider'
            value={
              initialRows.map((v) => v[0])
            }
            marks={initialRows.map((v) => ({value: Number(v[0]) , label: v[2]}))}
            sx={{
              ...initialRows.reduce((acc :any , v, i) => {
                if (v[1] === 'Working') {
                  acc[`& .MuiSlider-thumb[data-index="${i}"]`] = {
                    color: '#64C38E',
                    zIndex: 22,
                  };
                } else if (v[1] === 'Lunch') {
                  acc[`& .MuiSlider-thumb[data-index="${i}"]`] = {
                    color: '#F2BC8C',
                    zIndex: 22,
                  };
                } else if (v[1] === 'Break') {
                  acc[`& .MuiSlider-thumb[data-index="${i}"]`] = {
                    color: '#EF9EA0',
                    zIndex: 22,
                  };
                } else if (v[1] === 'Meet') {
                  acc[`& .MuiSlider-thumb[data-index="${i}"]`] = {
                    color: '#4680CD',
                    zIndex: 22,
                  };
                } else if (v[1] === 'Offline') {
                  acc[`& .MuiSlider-thumb[data-index="${i}"]`] = {
                    color: '#BEBEBE',
                    zIndex: 22,
                  };
                }
                return acc;
              }, {}),
            }}
          >
          
          </Slider>
          <div className='section-span'> 
          {
            
            WithFinal.map((v , i) => (
              <span className='' key={i} 
              style={{ width: `${v[0]}%`,color: `${
                v[1] === 'Working' ? '#64C38E' :
                v[1] === 'Lunch' ? '#F2BC8C' :
                v[1] === 'Break' ? '#EF9EA0' :
                v[1] === 'Meet' ? '#4680CD' :
                v[1] === 'Offline' ? '#BEBEBE' : '#BEBEBE'
              }` , position : 'relative' , zIndex:"20" , height: "5px" , bottom : "44px", borderRadius:"inherit", border:"1px solid currentColor" ,
              background: `${
                v[1] === 'Working' ? '#64C38E' :
                v[1] === 'Lunch' ? '#F2BC8C' :
                v[1] === 'Break' ? '#EF9EA0' :
                v[1] === 'Meet' ? '#4680CD' :
                v[1] === 'Offline' ? '#BEBEBE' : '#BEBEBE'
              }`
            }}
              >
              </span>
            ))
          }
          </div>

        </Box>
        </div>
    </div>
    
  );
  
};

export default TimeLine;
