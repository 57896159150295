import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useEffect } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;



// a custom hook to dispatch an action on mount
export const useAppEffect = (effect: React.EffectCallback, deps?: React.DependencyList) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(effect);
    }, deps);
};

