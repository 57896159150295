import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './services/authApi';
import {setupListeners} from '@reduxjs/toolkit/query/react';
import authSlice from './../features/authSlice';
import dashboardState from '../features/dashboardSlice';
import { statusApi } from './services/dashboardApi';
import membreSlice from '../features/membreSlice';
import { membresApi } from './services/membreApi';
import activitySlice from '../features/activitySlice';
import { activityApi } from './services/activityApi';


export const store = configureStore({
  reducer: {
    auth: authSlice,
    [authApi.reducerPath]: authApi.reducer,
    dashboard: dashboardState,
    [statusApi.reducerPath]: statusApi.reducer,
    membres: membreSlice,
    // [membresApi.reducerPath]: membresApi.reducer,
    activities: activitySlice,
    // [activityApi.reducerPath]: activityApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      authApi.middleware,
      statusApi.middleware,
      // membresApi.middleware,
      // activityApi.middleware,
    );
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);
