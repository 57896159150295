import { ApiSlice } from '../api/apiSlice';

export const activityApi = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        getActivities: builder.query({
            query: () => ({
                url: 'activities',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }),
        searchDataActivities: builder.mutation({
            query: (body:{date:any}) => ({
                url: 'activities',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: body,
            })
        }),
        getTimeLine : builder.query({
            query: () => ({
                url: 'timeLine',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }),
        paginationAvtivities: builder.mutation({
            query: (body:{page:number}) => ({
                url: 'activities',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: body,
            })
        }),
    }),
})

export const { useGetActivitiesQuery , useSearchDataActivitiesMutation , useGetTimeLineQuery , usePaginationAvtivitiesMutation } = activityApi;


