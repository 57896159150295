import React, { useState } from 'react';
import { logout } from '../../features/authSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdOutlineDriveEta } from 'react-icons/md';
import { RxDashboard } from 'react-icons/rx';
import { BiDoughnutChart , BiLogOut } from 'react-icons/bi';
import { AiOutlineUser } from 'react-icons/ai';
import { BsCalendar2Day } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useLogoutMutation } from '../services/dashboardApi';


export function useSlideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logoutMutation ,
    { error: logoutError , isSuccess: logoutSuccess}
  ]  = useLogoutMutation();

  const isActive = useState()

  const handleLogout = () => {

    logoutMutation({});
    
    dispatch(logout());

    toast.success('Logout Success');
    navigate('/login');
  };

  return {
    handleLogout,
    
  };
}


function SlideBar() {
  const { handleLogout } = useSlideBar();

  return (
            <nav
       id="sidebarMenu"
       className="collapse d-lg-block sidebar collapse header-dash"
       >
    <div className="position-sticky">
      <div className="list-group list-group-flush mx-3 mt-5">
      <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
                <NavLink to="/dashboard" id='hover' className="my-1 text-start fs-6 btnLink">
                <RxDashboard  className='mx-2'/>
                <span>Dashborad</span> 
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Activity" id='hover' className="my-1 text-start fs-6 btnLink">
                <BiDoughnutChart className='mx-2'/>
                <span>My Activity</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Satistique" id='hover' className="my-1 text-start fs-6 btnLink">
                <MdOutlineDriveEta className='mx-2'/>
                <span>Statistique</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Membres" id='hover' className="my-1 text-start fs-6 btnLink">
                <AiOutlineUser className='mx-2'/>
                <span>Members</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Hollidays" id='hover' className="my-1 text-start fs-6 btnLink">
                <BsCalendar2Day className='mx-2'/>
                <span>Hollidays</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Settings" id='hover' className="my-1 text-start fs-6 btnLink">
                <FiSettings className='mx-2'/>
                <span>Settings</span>
                </NavLink>
            </li>
            <li className='logout nav-item'>
                <a id='hover' onClick={handleLogout} className="my-1 text-start fs-6 btnLink">
                <BiLogOut className='mx-2'/>
                <span>Logout</span>
                </a>
            </li>
        </ul>
      </div>
    </div>
  </nav>
  );
}

export default SlideBar;
