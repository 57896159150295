import React from 'react';
import './App.css';
import { Routes, Route , Navigate } from 'react-router-dom';
import Dashboard from './app/pages/Dashboard';
import Login from './app/pages/Login';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MainRouter from './Router';



function App() {
  return (
    <div className="App">
      <ToastContainer />
      <MainRouter/>
    </div>
  );
}

export default App;
