import React from 'react';
import { HiOutlineTrendingUp , HiOutlineTrendingDown} from 'react-icons/hi';
import { useSelector } from 'react-redux';


const CardChart = () => {

    const {statistics} = useSelector((state: any) => state.dashboard);

    return (
        <div>
            <div className="container-fluid">
                <div className="header-body mt-5">
                    <div className="row">
                        <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                            <div className="row">
                                <div className="col">
                                <h6 className="card-title text-uppercase text-dark mb-0 text-start">WORKED HOURS</h6>
                                </div>
                            </div>
                            <p className="mt-2 mb-0 text-dark text-sm text-start d-flex justify-content-between align-items-center">
                                <span className="h2 font-weight-bold mb-0">{statistics.current_day_working_hours}H</span>
                                <span className="text-nowrap">+11.01% <HiOutlineTrendingUp/></span>
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                            <div className="row">
                                <div className="col">
                                <h6 className="card-title text-uppercase text-dark mb-0 text-start">WORKED THIS WEEK</h6>
                                </div>
                            </div>
                            <p className="mt-2 mb-0 text-dark text-sm text-start d-flex justify-content-between align-items-center">
                                <span className="h2 font-weight-bold mb-0"> { statistics.current_week_working_hours}H</span>
                                <span className="text-nowrap">+9.15% <HiOutlineTrendingUp/></span>
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                            <div className="row">
                                <div className="col">
                                <h6 className="card-title text-uppercase text-dark mb-0 text-start">EXTRAS HOURS</h6>
                                </div>
                            </div>
                            <p className="mt-2 mb-0 text-dark text-sm text-start d-flex justify-content-between align-items-center">
                                <span className="h2 font-weight-bold mb-0"> {statistics.current_month_extra_hours}H</span>
                                <span className="text-nowrap">-1.48% <HiOutlineTrendingDown/></span>
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                            <div className="row">
                                <div className="col">
                                <h6 className="card-title text-uppercase text-dark mb-0 text-start">COMPLIANCE</h6>
                                </div>
                            </div>
                            <p className="mt-2 mb-0 text-dark text-sm text-start d-flex justify-content-between align-items-center">
                                <span className="h2 font-weight-bold mb-0"> 93% </span>
                                <span className="text-nowrap">-1.48% <HiOutlineTrendingDown/></span>
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardChart;