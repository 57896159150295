import React from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';

const PieChart = () => {
  const {donutChart} = useSelector((state: any) => state.dashboard.charts);

  const { options, series } = donutChart;

  return (
    <div className="pie-chart">
        <h4 className='text-dark my-3'> Time aspent par status ( Today )</h4>
      <Chart options={
        {
          ...options,
        }
        }
         series={
          [
            ...series
          ]
        } 
      type="donut" className="pie-chart-component" />
    </div>
  );
};

export default PieChart;
