import React from 'react';

const kpisChart = () => {
    return (
        <div>
            <div>
                <h4 className='text-dark my-3 text-start'>KPIS</h4>
                <div>
                    <label  className="form-label text-dark d-flex  content-kpis">Punctuality</label>
                    <input type="range" className="form-range my-4" min="0" max="20"id="customRange2"/>
                </div>
                <div>
                    <label  className="form-label text-dark d-flex  content-kpis">Integrity</label>
                    <input type="range" className="form-range my-4" min="0" max="20" id="customRange2"/>
                </div>
                <div>
                    <label  className="form-label text-dark d-flex  content-kpis">Engagement</label>
                    <input type="range" className="form-range my-4" min="0" max="20" id="customRange2"/>
                </div>
            </div>
        </div>
    );
};

export default kpisChart;