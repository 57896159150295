import {useState,useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {MDBInput} from "mdb-react-ui-kit";
import { useLoginMutation  } from '../services/authApi';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../hooks';
import { selectAuth, setAuth } from '../../features/authSlice';
import logo from '../../img/logo Major Media 1.png';
import { useSelector } from 'react-redux';
import img from '../../img/majormedia-galerie-1-scaled 1.jpg'


const initialState = {
  firstName: "",
  lastName: "",
  login: "",
  password: "",
  confirmPassword: "",
}

const Login = () => {

  const { token } = useSelector(selectAuth);

  const [formValue , setFormValue ] = useState(initialState);
  const {firstName , lastName , login , password , confirmPassword} = formValue;
  const [showRegister , setShowRegister] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  if(token){
    navigate('/dashboard');
  }

  const [loginUser , 
    {
      data:loginData , 
      isSuccess:isLoginSuccess , 
      isError:isLoginError , 
      error:loginError
    }
  ] = useLoginMutation();

  const handleChange = (e:any) => {
    setFormValue({...formValue , [e.target.name]: e.target.value});
  }

  const handleLogin = async () => {
    if(login && password){
      await loginUser({login , password});
    }else{
      toast.error('Please enter Email and password');
    }
  }

  useEffect(() => {
    if(isLoginSuccess){
      toast.success('Login Success');
      dispatch(setAuth({user: loginData.user , token: loginData.token }))
      navigate('/dashboard');
    }
  },[isLoginSuccess]);

  useEffect(() => {
    if(isLoginError){
      toast.error('Email or password is incorrect');
    }
  },[isLoginError]);

  return (
      <section className='vh-100'>
          <div >
      <div className="row align-items-cente m-auto">
      <div className="col-md-6 px-0 d-none d-sm-block position-relative position-relative-example">
            <img src={img} alt="Login image" className="w-100 vh-100 image" />
          </div>
        <div className="col-md-6 bg-white">
          <div className="">
          <img src={logo} alt="" className="my-5 w-50 " />
          </div>
          <div className="text-start my-2 welcome-login">
            <h1 className="">Welcome back</h1>
            <div className="form-text ">Login to your account</div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="w-75" >
              <div className="mb-3">
                <label className="form-label d-flex justify-content-start">Email</label>
                <MDBInput
                  type="email"
                  className="form-control p-2"
                  name='login'
                  value={login}
                  onChange={handleChange}
                  placeholder="email@gmail.com"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label className="form-label  d-flex justify-content-start">Password</label>
                <MDBInput
                  type="password"
                  name='password'
                  value={password}
                  onChange={handleChange}
                  className="form-control p-2"
                  placeholder="*************"
                />
              </div>
              <div className="mb-3 form-check d-flex justify-content-between">
                <div>
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                  <label className="form-check-label">Remember me</label>
                </div>
              </div>
              <button type="submit" onClick={()=> handleLogin()}  className="w-100 btn-login">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Login;