import {createSlice , PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface dashboardState {
    states : 
        {
            id: number;
            title: string;
        }[];

    statistics: {
        current_day_working_hours: number;
        current_week_working_hours: number;
        current_month_extra_hours: number;
    }
    charts: {
        lineChart: {
            options: {
                chart: {},
                xaxis: {},
                yaxis: {},
            }
            series: [
                {
                    name: string;
                    data: number[];
                }
            ]
        }
        donutChart:{
            options: {
                chart: {},
                labels: string[];
            }
            series: []
        }
    }
}

const initialState: dashboardState = {
    states : [],
    statistics: {
        current_day_working_hours: 0,
        current_week_working_hours: 0,
        current_month_extra_hours: 0,
    },
    charts: {
        lineChart: {
            options: {
                chart: {},
                xaxis: {},
                yaxis: {},
            },
            series: [
                {
                    name: '',
                    data: [],
                }
            ]
        },
        donutChart:{
            options: {
                chart: {},
                labels: [],
            },
            series: []
        }
    }
}

export const dashboardState = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDashboardState: (state, action: PayloadAction<dashboardState>) => {
            state.states = action.payload.states;
            state.statistics = action.payload.statistics;
            state.charts = action.payload.charts;
        }
    }
})

export const selectStatusUser = (state: RootState) => state.dashboard;

export const {getDashboardState} = dashboardState.actions;

export default dashboardState.reducer;
