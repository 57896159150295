import React from 'react';
import TimeLine from './../components/activity/timeLine';
import TableActivity from './../components/activity/tableActivity';
const Activity = () => {
    return (
        <div>
            <TimeLine/>
            <TableActivity/>
        </div>
    );
};

export default Activity;