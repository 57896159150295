import React, { useState } from 'react';
import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead ,MDBInputGroup ,MDBIcon ,MDBSpinner} from 'mdb-react-ui-kit';
import { HiOutlineFilter } from 'react-icons/hi';
import { useAppDispatch } from '../hooks';
import { useGetMembresQuery , useFilterMembreMutation , useSearchMembresMutation , usePaginationMemebresMutation } from '../services/membreApi';
import  {setMembres} from '../../features/membreSlice';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';


const Membres = () => {
  
  const columns = ['Full Name', 'Email', 'Phone Number', 'Status'];

  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useGetMembresQuery({})

  const membres = useSelector((state: any) => state.membres)

  const [rows, setRows] = useState<any[]>([]);

  const last_page = membres.last_page;

  const states = useSelector((state: any) => state.dashboard.states);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');  

  const [search ,
    {
      data:searchData ,
      isLoading:issearchLoading ,
      isSuccess:issearchSuccess ,
      isError:issearchError ,
      error:searchError
    }
  ] = useSearchMembresMutation();


  const [filter ,
    {
      data:filterData ,
      isLoading:isfilterLoading ,
      isSuccess:isfilterSuccess ,
      isError:isfilterError ,
      error:filterError
    }
  ] = useFilterMembreMutation();

  const [
    paginationMembres ,
    {
      data:paginationData ,
      isLoading:ispaginationLoading ,
      isSuccess:ispaginationSuccess ,
      isError:ispaginationError ,
      error:paginationError
    }
  ] = usePaginationMemebresMutation();



  React.useEffect(() => {
    if (data) {
      dispatch(setMembres(data))
    }
  })

  React.useEffect(() => {
    const initializeRows = async () => {
      if (membres.data) {
        setRows(membres.data);
      } 
    };

    initializeRows();
  }, [data, dispatch, membres]);

  React.useEffect(() => {
    if (searchData) {

      setRows(searchData.data)
    }
  })

  React.useEffect(() => {
    if (filterData) {
      setRows(filterData.data)
    }
  })

    // search by name or email or phone number or status
    const handleSearchChange = async (e: any) => {
      setSearchTerm(e.target.value);
      await search({search: e.target.value});
    };

    // search by status
  const handleSortChange = async (e:any) => {
    setSortOption(e.target.value);
    await filter({state: e.target.value});
  };

 
  
  // pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = membres.data.slice(indexOfFirstItem, indexOfLastItem);

  const npages = Math.ceil(membres.data.length / itemsPerPage);
  const pages = new Array(npages).fill(null).map((v, i) => i + 1);

 

  const change = async (page:any) => {
    setCurrentPage(page);
    setCurrentPage(page.selected + 1);
    await paginationMembres({page : page.selected + 1})
  };

  const next = () => {
    if (currentPage < pages.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }

  };

  

  React.useEffect(() => {
    if(ispaginationSuccess){
      setRows(paginationData.data.data)
    }
  })

  
  if(isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
        <MDBSpinner role='status'>
          <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
      </div>
    )
  }

  return (
    <MDBContainer className='my-3 bg-white rounded-3 section-membres'>
      <div className=' pt-3 px-3'>
        <div className='text-start'>
          <h3 className='py-3'>{rows.length} members</h3>
        </div>
        <div className='d-flex justify-content-between align-items-center w-100 input-group'
        >
            <MDBInputGroup className='p-1 w-50 rounded-3'
             noBorder textBefore={<MDBIcon fas icon='search' />}>
            <input className='form-control w-25' type='text'
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder='Search' />
            </MDBInputGroup>
          <div className='sort-select d-flex'>
            <label htmlFor='sortSelect' className='label-sort'>
                Sort by:
            </label>
            <div className="position-relative">
            <select className="form-select select-filter" value={sortOption}
              onChange={handleSortChange} style={{padding:'5px 14px 5px 14px',width:'140px',textAlign:'center'}}>
              <option value={''}>Filter</option>

              {
                states.map((state:any) => (
                  <option value={state.id}>{state.title}</option>
                ))
              }
            </select>
            <HiOutlineFilter className="position-absolute top-50 translate-middle-y" style={{right:'117px'}}/>
          </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column justify-content-between section-table-pagination'>
      <MDBTable responsive>
        <MDBTableHead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                style={{
                    color: 'gray',
                }}
              >
                {column}
              </th>
            ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {
            issearchLoading || isfilterLoading || ispaginationLoading ? (
            <tr >
              <td colSpan={4} className='text-center'>
              <MDBSpinner role='status'>
                <span className='visually-hidden'>Loading...</span>
              </MDBSpinner>
              </td>
            </tr>
          ) :
            rows.length== 0 ? (
              <tr>
                <td colSpan={4} className='text-center'>
                  <div className='d-flex justify-content-center align-items-center'>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <p className='text-center'>No data found</p>
                    </div>
                  </div>
                </td>
              </tr>
            ) :

            rows.map((element:any , index)=> (
              <tr key={element.id}>
                <td className='align-middle text-center'>
                  <div className='d-flex justify-content-evenly align-items-center'>
                    <div className='avatar'>
                      <img
                        src={element.avatarUrl}
                        className='rounded-circle'
                        alt=''
                        style={{
                          width: '30px',
                          height: '30px',
                        }}
                      />
                    </div>
                      <div className='fw-bold'>{element.name} {element.surname}</div>
                  </div>
                </td>
                <td className='align-middle text-center'>{element.email}</td>
                <td className='align-middle text-center'>{element.phone}</td>
                <td className='align-middle text-center'>
                  {
                    element.lastLogState.title === 'Break'
                    ? <div className='status-activity' style={{border: '1px solid #EB5757' , color: '#EB5757'}} >{element.lastLogState.title}</div>
                    : element.lastLogState.title === 'Working'
                    ? <div className='status-activity' style={{border: '1px solid #27AE60' , color: '#27AE60'}} >{element.lastLogState.title}</div>
                    : element.lastLogState.title === 'Lunch'
                    ? <div className='status-activity' style={{border: '1px solid #E28A3C' , color: '#E28A3C'}} >{element.lastLogState.title}</div>
                    : element.lastLogState.title === 'Meet'
                    ? <div className='status-activity' style={{border: '1px solid #0253BC' , color: '#0253BC'}} >{element.lastLogState.title}</div>
                    : <div className='status-activity' style={{border: '1px solid #494949' , color: '#494949'}} >{element.lastLogState.title}</div>
                  }
                </td>
              </tr>

            ))
          }
        </MDBTableBody>
      </MDBTable>
      <div className='py-2 px-3 d-flex justify-content-center'>
        <nav aria-label='Page navigation example'>
        <ReactPaginate
            pageCount={last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={change}
            containerClassName={'pagination'}
            activeClassName={'active'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'nextpeviousLink'}
            previousLinkClassName={'nextpeviousLink'}
            nextClassName={'nextpeviousLink'}
            nextLinkClassName={'nextpeviousLink'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            nextLabel={'»'}
            previousLabel={'«'}
            className='pagination'
          />
              {/* <ul className='pagination p-1'>
                <li className='page-item'>
                  <a aria-label='Previous' className='page-link' onClick={prev} style={{background: "#4017AE" , color:"white"}}>
                    <span aria-hidden='true'>«</span>
                  </a>
                </li>
                {pages.map((page , i) => (
                  <li className={` ${currentPage === page ? 'active-link' : 'desactive-link' }`}>
                    <a onClick={() =>change(page)} className='page-link' key={i}>{page}</a>
                  </li>
                ))}
                <li className='page-item'>
                  <a aria-label='Next' onClick={next} className='page-link' style={{background: "#4017AE" , color:"white"}}>
                    <span aria-hidden='true'>»</span>
                  </a>
                  </li>
              </ul> */}
            </nav>
        </div>
      </div>
    </MDBContainer>
    );
};

export default Membres;
