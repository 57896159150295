import React from 'react';
import CardChart from './cardChart';
import LineChart from './apexChart/lineChart';
import PieChart from './apexChart/pieChart';
import BarChart from './apexChart/barChart';
import KipsChart from './apexChart/kpisChart';



const main = () => {
    
    return (
        <>
            <CardChart/>
                <div className='container-fluid mt-5'>
                    <div className='header-body'>
                        <div className="row d-flex justify-content-between px-3 flex-wrap gap-4">
                            <div className="col-xl-6 col-lg-12 bg-white rounded pie-chart">
                            <LineChart/>
                            </div>
                            <div className="col-xl-5 col-lg-12 bg-white rounded pie-chart">
                            <PieChart/>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-between px-3 flex-wrap gap-4 section-chart-pie my-5">
                            <div className="col-xl-6 col-lg-12 bg-white rounded mb-5">
                            <BarChart/>
                            </div>
                            <div className="col-xl-5 col-lg-12 bg-white rounded mb-5">
                            <KipsChart/>
                            </div>
                        </div>
                    </div>
                </div>
        </>
                
    );
};

export default main;