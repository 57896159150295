import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({baseUrl:'https://mmintegrity.chimatchapp.com/api/v1/'}),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body:{login:string; password:string}) => ({
                url: 'login',
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
})

export const {useLoginMutation } = authApi;
    