import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SlideBar from './../components/slideBar';
import NavBar from './navBar';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectAuth } from '../../features/authSlice';
import LoadingToRedirect from './LoadingToRedirect';





const InnerContent = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useSelector(selectAuth);
    

    if(token){

        return (
            <div>
                <header>
                    <SlideBar/>
                    <NavBar/>
                </header>
                <div  className='content-main'>
                    <main className='header-dash pt-5'>
                        <div className="container pt-4" style={{height: 'max-content'}}>
                            <Outlet/>
                        </div>
                    </main>
                </div>
            </div>
        );
    }else{
        return <LoadingToRedirect/>;
    }

    
};

export default InnerContent;