import {createSlice , PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface AuthState {
    user:null;
    token: any | null;


}

const initialState: AuthState = {
    user:null,
    token: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<{user:any,token:any}>) => {

            const { user , token } = action.payload;

                state.user = user;
                state.token = token;
            
            localStorage.setItem('USER', JSON.stringify(
                {
                    user: action.payload.user,
                    token: action.payload.token
                }
            ));
            

        },
        logout: (state) => {
            localStorage.clear();
            state.user = null;
            state.token = null;
        }
    }
})


export const selectAuth = (state: RootState) => state.auth;


export const {setAuth,logout} = authSlice.actions;

export default authSlice.reducer;

export const seelectCurrentUser = ( state:any ) => state.auth.user;
export const selectCurrentToken = ( state:any ) => state.auth.token;