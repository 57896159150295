import React from 'react';
import Chart from "react-apexcharts";

const BarChart = () => {
  const [state, setState] = React.useState({
    series: [
        {
            data: [1, 2, 3, 4, 5]
        }
    ],
    options: {
      chart: {
        id: "basic-line"
      },
      xaxis: {
        categories: ["28 Jul 2019", "07 Jan 2019", "18 May 2019", "23 May 2019", "17 Feb 2019"]
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      }
    }
  });

  return (
    <div className="line-chart">
        <h4 className='text-dark my-3'>Compliance report</h4>
      <Chart options={state.options} series={state.series}  type="bar" height={250} className="barChart"/>
    </div>
  );
};

export default BarChart;