import React from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';

const LineChart = () => {

  const {lineChart} = useSelector((state: any) => state.dashboard.charts);

  const { options, series } = lineChart;

  return (
    <div className="line-chart">
        <h4 className='text-dark my-3'>Worked hours daily ( current week )</h4>
        <Chart options={
          {
            ...options,
            xaxis: {
              ...options.xaxis
            }
          }

        } series={
          [
            {
              ...series[0]
            }
          ]
        } type={options.chart.type} className="line-chart-component" />
    </div>
  );
};

export default LineChart;
