import React, { useEffect } from 'react';
import { Routes, Route , Navigate } from 'react-router-dom';
import Login from './app/pages/Login';
import Dashboard from './app/pages/Dashboard';
import Activity from './app/pages/Activity';
import Satistique from './app/pages/Satistique';
import Membres from './app/pages/Membres';
import InnerContent from './app/components/InnerContent';
import PrivateRoute from './app/components/PrivateRoute';
import { useAppDispatch } from './app/hooks';
import { setAuth } from './features/authSlice';
import Hollidays from './app/pages/Hollidays';
import Settings from './app/pages/Settings';
import PageNotFound from './app/pages/pageNotFound';


const MainRouter = () => {

    const dispatch = useAppDispatch();
    const user = JSON.parse(localStorage.getItem("USER") || "{}");

    useEffect(() =>{
        dispatch(setAuth(user))
    },[])
    
    return (
        <div>
            
            <Routes>
                <Route element={<InnerContent/>}>
                <Route path='/dashboard' element={
                    <PrivateRoute>
                        <Dashboard/>
                    </PrivateRoute>
                }/>
                    <Route path='/activity' element={<Activity/>}/>
                    <Route path='/satistique' element={<Satistique/>}/>
                    <Route path='/membres' element={<Membres/>}/>
                    <Route path='/Hollidays' element={<Hollidays/>}/>
                    <Route path='/Settings' element={<Settings/>}/>
                </Route>
                <Route path='/' element={<Navigate to='/login'/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            
        </div>
    );
};

export default MainRouter;