import { ApiSlice } from '../api/apiSlice';

export const membresApi = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        getMembres: builder.query({
            query: () => ({
                url: 'users',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }),
        searchMembres: builder.mutation({
            query: (body:{search:any}) => ({
                url: 'users',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: body,
            })
        }),
        filterMembre : builder.mutation({
            query: (body:{state:any}) => ({
                url: 'users',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: body,
            })
        }),
        paginationMemebres: builder.mutation({
            query: (body:{page:number}) => ({
                url: 'users',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: body,
            })
        }),
    }),
})

export const { useGetMembresQuery , useSearchMembresMutation , useFilterMembreMutation , usePaginationMemebresMutation } = membresApi;
